import { Button, Card, Form, Pagination, Select } from "antd";
import Search from "antd/lib/transfer/search";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import config from "../../config.json";
import ShowClients from "./components/ShowClients";
import { useNavigate, createSearchParams } from "react-router-dom";
import { clearToken } from "../../scripts/token";
import { SearchOutlined } from "@ant-design/icons";
import useIsMobile from "../../hooks/useIsMobile";

const filterOptions = [
  {
    value: "all",
    label: "Все",
  },
  {
    value: "exists-store",
    label: "Есть магазин",
  },
  {
    value: "not-exists-store",
    label: "Нет магазина",
  },
  {
    value: "access-analytics",
    label: "Аналитика",
  },
];

const Client = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useIsMobile()
  const [clients, setClients] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const [limit, setLimit] = useState(searchParams.get("limit") || 10);
  const [query, setQuery] = useState(searchParams.get("q") || "");
  const [filter, setFilter] = useState(
    searchParams.get("filter") || filterOptions[0].value
  );

  useEffect(() => {
    fetchClients();
  }, [currentPage, limit]);

  const handleSearch = () => {
    navigate({
      pathname: "",
      search: createSearchParams({
        page: currentPage,
        q: query || "",
        limit,
        filter,
      }).toString(),
    });

    fetchClients();
    setCurrentPage(1);
  };

  const fetchClients = () => {
    setIsLoading(true);

    axios
      .get(
        `${config.service}/api/client?page=${currentPage}&limit=${limit}&q=${query}&filter=${filter}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => {
        setClients(data.data.data);
        setTotalCount(data.data.total);
        navigate({
          pathname: "",
          search: createSearchParams({
            page: 1,
            q: query || "",
            limit,
            filter,
          }).toString(),
        });
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPagination = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination
          current={currentPage}
          total={totalCount}
          disabled={isLoading}
          onChange={(value) => {
            if (value === currentPage) {
              return;
            }

            setCurrentPage(value);

            navigate({
              pathname: "",
              search: createSearchParams({
                page: value,
                q: query || "",
                limit,
              }).toString(),
            });
          }}
          defaultCurrent={currentPage}
          showTotal={(total, range) => `${range[0]}-${range[1]} из ${total}`}
          onShowSizeChange={async (current, size) => {
            if (size === limit) {
              return;
            }

            setLimit(size);

            navigate({
              pathname: "",
              search: createSearchParams({
                page: currentPage,
                q: query || "",
                limit: size,
              }).toString(),
            });
          }}
          pageSize={limit}
          defaultPageSize={limit}
        />
      </div>
    );
  };

  const getSearchForm = () => {
    return (
      <Form
        className="store-inputs-flex"
        onFinish={handleSearch}
        initialValues={{
          filter: filter,
          query: query,
        }}
      >
        <Form.Item
          name="filter"
          style={{ width: 200 }}
          className="d-flex m-0 p-0 me-1"
        >
          <Select
            disabled={isLoading}
            value={filter}
            onChange={setFilter}
            options={filterOptions}
            style={{
              minWidth: 150,
              width:'100%'
            }}
            className="me-1"
          />
        </Form.Item>
        <Form.Item name="query" className="w-100">
          <Search
            placeholder="Поиск по ключевому слову"
            allowClear
            enterButton="Поиск"
            size="large"
            disabled={isLoading}
            value={query}
            onChange={(event) => {
              setQuery(event.target.value);
            }}
          />
        </Form.Item>
        <Button
          htmlType="submit"
          className="ms-1"
          type="primary"
          loading={isLoading}
          icon={<SearchOutlined />}
        >
          Поиск
        </Button>
      </Form>
    );
  };

  return (
    <Card className={isMobile ? 'mx-2' : "mx-3"}>
      <h1
        style={{
          fontSize: 18,
        }}
      >
        Клиенты
      </h1>

      {getSearchForm()}
      <ShowClients clients={clients} isLoading={isLoading} />
      {getPagination()}
    </Card>
  );
};

export default Client;
