import { Card } from "antd";
import kaspiIcon from "../../../assets/icons/kaspi-logo.ico";
import jmartIcon from "../../../assets/icons/jmart-icon.png";
import halykIcon from "../../../assets/icons/halyk-icon.png";
import chatGPTIcon from "../../../assets/icons/chatgpt-icon.png";
import ozonIcon from "../../../assets/icons/ozon-icon.png";
import wildberriesIcon from "../../../assets/icons/wildberries-icon.png";
import React, { useState } from 'react';
export const ServiceHealthCard = ({ service }) => {
  const [visibleIndexes, setVisibleIndexes] = useState({ 5: false, 8: false });
  const toggleMessageVisibility = (index) => {
    setVisibleIndexes((prev) => ({ ...prev, [index]: !prev[index] }));
  };
  let bgColor = "bg-success";
  if (service.status === 3) {
    bgColor = "bg-danger";
  } else if (service.status === 2) {
    bgColor = "bg-warning";
  }
  let icon = kaspiIcon;
  if (service.marketplaceName === "Halyk") {
    icon = halykIcon;
  } else if (service.marketplaceName === "Jmart") {
    icon = jmartIcon;
  } else if(service.marketplaceName === "ChatGPT") {
    icon = chatGPTIcon;
  } else if(service.marketplaceName === "Ozon") {
    icon = ozonIcon;
  } else if(service.marketplaceName === "Wildberries") {
    icon = wildberriesIcon;
  }

  const messages = (service.messages || []).filter((v) => v);

  return (
    <Card
      className={`mb-3 me-3 ${bgColor}`}
      style={{ width: 350, position: "relative" }}
    >
      <h3
        className="m-0"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {service.serviceName}
        <img src={icon} alt="icon" style={{ width: 45, height: 45 }} />
      </h3>
      <div className="ms-3 mt-2">

        {
          service.serviceName !== "Рассылка WhatsApp" &&
            <ul className="m-0">
              {messages.map((message, index) => {
                return <li key={index}>{message}</li>;
              })}
            </ul>
        }
        
        {
          service.serviceName === "Рассылка WhatsApp" &&
          <>
          <ul className="m-0">
            {messages.map((message, index) => {
              if (index === 5 || index === 8) {
                const isVisible = visibleIndexes[index];
  
                return (
                  <li key={index}>
                    {isVisible ? (
                      <>
                        {message.split("</br>").map((v, idx) => (
                          <p key={idx}>{v}</p>
                        ))}
                        <button
                          onClick={() => toggleMessageVisibility(index)}
                          style={{ color: 'Blue' }}
                        >
                          Скрыть
                        </button>
                      </>
                    ) : (
                      <button
                        onClick={() => toggleMessageVisibility(index)}
                        style={{  color: 'Blue' }}
                      >
                        Показать
                      </button>
                    )}
                  </li>
                );
              }
              return <li key={index}>{message}</li>;
            })}
          </ul>
        </>
        }
        
      </div>
    </Card>
  );
};
